/* Footer.css */
.main__footer {
    width: 35%;
    padding: 1rem 0;
    background-color: #fff;
    border-top: 1px solid #e7e7e7;
    position: fixed;
    align-items: center;
    text-align: center;
    bottom: 0;
    left: 32%; 
}
.policy-list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center; 
    align-items: center;
    white-space: nowrap;
}

.policy-list__item {
    padding: 0 10px;
}

.policy-list__item a {
    color: #333;
    text-decoration: none; 
    font-size: 13px; 
    font-weight: normal;
}

.policy-list__item a:hover {
    text-decoration: underline; 
}
